.dorms {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 50px;
  }
  
  .dormList {
    width: 70vw;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    place-items: center;
  }
  
  /* MENU ITEM STYLING */

  .dormItem button {
    border-radius: 50px; /* Make the button oval */
    background-color: #1d11a5; 
    color:  white; 
    border: none; 
    padding: 10px 20px; 
    font-size: 16px;
    margin-top: 10px
  }
  
  .dormItem button:hover {
    background-color: #5f6194;; /* Change color on hover (adjust as needed) */
  }
  
  .dormItem {
    border-radius: 15px;
    width: 300px;
    height: 300px;
    margin: 20px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    color: white; 
  }
  .dormItem:hover {
    box-shadow: 0px 10px 15px rgba(24, 57, 166, 0.5);
    transition: 0.3s ease-in;
    cursor: pointer;
  }
  
  .dormItem div {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .dormItem h1,
  .dormItem p {

    margin: 10px;

  }

  .responsive-map {
    border-radius: 15px;
    margin: auto;
    width: 90%; /* or any other size */
    max-width: 961; /* maximum size */
    max-height: 634; /* maximum size */
    height: auto; /* maintains aspect ratio */
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.4);
    margin-top: 10px;
    margin-bottom: 40px;
  }
  
  @media only screen and (max-width: 1300px) {
    .dormList {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  @media only screen and (max-width: 800px) {
    .dormList {
      grid-template-columns: 1fr;
    }
  }