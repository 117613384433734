.footer {
    width: 100%;
    height: 100px;
    background-color: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

}

.footer p {
    color: white;
    align-items: center;
    margin: 20px;
}