.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

body {
  padding: 10;
  margin: 10;
}