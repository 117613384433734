/* RoomDetails.css */

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  font-size: 28px;
  margin-bottom: 20px;
}

.averages-section {
  margin-top: 20px;
  padding-top: 20px;
}

.averages {
  display: flex;
  justify-content: space-between;
}

.averages div {
  text-align: center;
}

.review-container {
  margin-top: 20px;
}

.review {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #f9f9f9;
}

.review p {
  margin: 0;
  margin-bottom: 5px;
}

.review p.rating {
  display: inline-block;
  margin-right: 20px;
}

textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  margin-bottom: 20px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

ul {
  list-style-type: none;
  padding: 0;
}
