.dormRooms {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-bottom: 50px;
}

.dormList {
  width: 70vw;
  height: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-items: center;
}

/* MENU ITEM STYLING */

.dormRoom button {
  border-radius: 50px; 
  background-color: #0e128e;
  color: white; 
  border: none; 
  padding: 10px 20px; 
  font-size: 16px; 

}

.dormRoom button:hover {
  background-color: #5f6194; 
}

.dormRoom {
  border-radius: 15px;
  width: 300px;
  height: 250px;
  margin: 20px;
  padding-top: 10px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}

.dormRoom:hover {
  box-shadow: 0px 10px 15px rgba(107, 104, 147, 0.5);
  transition: 0.3s ease-in;
  cursor: pointer;
}

.dormRoom div {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.dormRoom h1 {
  margin-top: 30px;
  margin-bottom: 15px;
}

.responsive-map {
  border-radius: 15px;
  margin: auto;
  width: 75%; /* or any other size */
  max-width: 800; /* maximum size */
  max-height: 527; /* maximum size */
  height: auto; /* maintains aspect ratio */
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.4);
  margin-top: 10px;
  margin-bottom: 40px;
}

.dormRoom p {
  margin-bottom: 20px;
}

@media only screen and (max-width: 1300px) {
  .dormList {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 800px) {
  .dormList {
    grid-template-columns: 1fr;
  }
}
