.navbar {
    width: 100%;
    height: 80px;
    background-color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .navbar .leftSide {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
  }
  
  .navbar .leftSide img {
    width: 90px;
  }
  
  .navbar .rightSide {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 50px;
  }
  
  .navbar a {
    color: white;
    text-decoration: none;
    margin: 0 50px;
  }
  
  .navbar .rightSide button {
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
  }
  
  .navbar .rightSide svg {
    font-size: 40px;
  }
  
  .navbar #open {
    padding-left: 0px;
  }
  .navbar #open img {
    display: none;
  }
  
  .navbar #close img {
    display: inherit;
  }
  
  
  .navbar #open a {
    width: 70px;
    margin: 5px;
  }
  @media only screen and (max-width: 900px) {
    .navbar .rightSide a {
      width: 70px;
    }
    .navbar .leftSide {
      padding-left: 20px;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .navbar .rightSide a {
      display: none;
    }
  
    .navbar .rightSide {
      justify-content: flex-end;
      padding-right: 20px;
    }
  
    .navbar .rightSide button {
      display: inherit;
    }
  }
  
  @media only screen and (min-width: 600px) {
    .navbar .rightSide button {
      display: none;
    }

  }